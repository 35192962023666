import { NAME } from '@/app/campaigns/constants';

import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'next-i18next';
import { Form, Field } from 'react-final-form';

import PlainTextInput from '@/ui/components/_ReduxFieldComponents/PlainTextInput';
import { cn } from '@/utils/cn';

import type { CampaignResource } from '@/app/campaigns/types';

export interface Props {
    onSubmit: (values: CampaignResource) => void;
    className?: string;
    initialValues: CampaignResource;
}

// Prevents empty campaign name
const validate = (values: CampaignResource) => {
    type ValidationErrors = { attributes: { name?: string } };

    const errors: ValidationErrors = { attributes: {} };

    if (!values.attributes?.name) {
        errors.attributes.name = 'required';
    }

    return errors as {};
};

const NameForm = ({ onSubmit, initialValues, className }: Props) => {
    const { t } = useTranslation(NAME);

    return (
        <Form validate={validate} initialValues={initialValues} onSubmit={onSubmit}>
            {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} className="flex items-center">
                    <Field
                        name="attributes.name"
                        component={PlainTextInput}
                        placeholder={t('funnel-name-placeholder')}
                        autoFocus
                        className={cn('w-full bg-transparent font-medium outline-none', className)}
                        customOnBlur={handleSubmit}
                    />

                    <button className="ml-2 text-gray-400 hover:text-gray-600" type="submit">
                        <CheckCircleIcon className="size-5" />
                    </button>
                </form>
            )}
        </Form>
    );
};

export default NameForm;
