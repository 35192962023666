import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { useMoveCampaign } from '@/app/campaigns/hooks/mutations/useMoveCampaign';
import { useCampaign } from '@/app/campaigns/hooks/queries/useCampaign';
import { useWorkspaces } from '@/app/workspaces/hooks/useWorkspaces';

interface Props {
    campaignId?: string;
}

const MoveToWorkspaceSubmenu = ({ campaignId }: Props) => {
    const { sortedWorkspaces, workspaceLimit } = useWorkspaces();
    const { data: campaign } = useCampaign(campaignId);
    const campaignWorkspaceId = campaign?.relationships?.workspace?.data?.id;
    const { mutate: moveCampaign } = useMoveCampaign(campaignId);

    const handleClick = (workspaceId: string) => async () => {
        if (campaignId) {
            moveCampaign({ workspaceId });
        }
    };

    const workspaceIdsWithLimit = sortedWorkspaces
        ?.slice(0, workspaceLimit)
        ?.filter((workspace) => workspace.id !== campaignWorkspaceId);

    return (
        <div className="flex w-52 flex-col rounded-lg border border-gray-200 bg-white p-2 text-sm">
            {workspaceIdsWithLimit?.map((workspace) => {
                return (
                    <DropdownMenu.Item asChild key={workspace?.id}>
                        <button
                            type="button"
                            onClick={handleClick(workspace?.id)}
                            className="w-full truncate rounded p-2 text-left text-gray-500 outline-none hover:bg-gray-100 hover:text-gray-800"
                        >
                            {workspace?.attributes?.name}
                        </button>
                    </DropdownMenu.Item>
                );
            })}
        </div>
    );
};

export default MoveToWorkspaceSubmenu;
