import { NAME } from '@/app/campaigns/constants';

import get from 'lodash/get';
import Link from 'next/link';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import RenameCampaignForm from '@/app/campaigns/components/RenameCampaignForm';
import { getCampaignIsArchived } from '@/app/campaigns/helpers';
import { getRenaming } from '@/app/campaigns/models/update';
import { useAppSelector } from '@/core/redux/hooks';
import { useDateFormat } from '@/hooks/useDateFormat';
import { useHasOverflow } from '@/hooks/useHasOverflow';
import Tooltip from '@/ui/components/Tooltip';
import { cn } from '@/utils/cn';
import { EMPTY_STRING } from '@/utils/empty';

import type { CampaignResource } from '../../../../types';

export interface Props {
    campaign: CampaignResource;
    link: string;
}

export const Body = ({ campaign, link }: Props) => {
    const { t } = useTranslation(NAME);
    const isArchived = getCampaignIsArchived(campaign);
    const dateTimestamp = get(campaign, 'attributes.updatedAt', EMPTY_STRING);
    const date = useDateFormat(new Date(dateTimestamp), 'PP');
    const renamingCampaignId = useAppSelector(getRenaming);
    const isActiveRenaming = renamingCampaignId === campaign.id;

    const name = campaign?.attributes.name;
    const nameElementRef = useRef();
    const hasNameElementOverflow = useHasOverflow(nameElementRef, 'horizontal');

    const nameContent = useMemo(
        () => (
            <Tooltip disabled={!hasNameElementOverflow} content={name}>
                <p
                    className="block overflow-hidden overflow-ellipsis whitespace-nowrap text-lg font-medium"
                    ref={nameElementRef}
                >
                    {name}
                </p>
            </Tooltip>
        ),
        [name, hasNameElementOverflow],
    );

    return (
        <div className="px-2 pb-4 pt-1">
            <div className="relative flex items-center">
                <div className="min-w-0 flex-grow truncate">
                    <div
                        className={cn('rounded-lg px-2 py-1 text-gray-800', {
                            'bg-gray-100': isActiveRenaming,
                        })}
                    >
                        {isActiveRenaming ? (
                            <RenameCampaignForm campaign={campaign} className="text-lg" />
                        ) : isArchived ? (
                            nameContent
                        ) : (
                            <Link href={link}>{nameContent}</Link>
                        )}
                    </div>
                    <p className="pl-2 text-xs text-gray-500">{t('last-updated-at', { date })}</p>
                </div>
            </div>
        </div>
    );
};

export default Body;
