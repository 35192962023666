import { NAME } from '@/app/campaigns/constants';

import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { CampaignColumnName, CampaignOrder } from '@/app/campaigns/types';
import { useWorkspaces } from '@/app/workspaces/hooks/useWorkspaces';

import { FunnelTable } from './FunnelTable';
import { TableItem } from './TableItem';

import type { CampaignResource, CampaignTableColumnItem } from '@/app/campaigns/types';

interface Props {
    campaigns: CampaignResource[];
}

export const Table = ({ campaigns }: Props) => {
    const { t } = useTranslation(NAME);
    const router = useRouter();
    const { activeWorkspace } = useWorkspaces();

    const { query } = router;
    const currentOrder = query.order as CampaignOrder;

    const handleSort = (columnId: CampaignOrder) => {
        let newOrder: CampaignOrder = CampaignOrder[columnId];
        const activeWorkspaceId = router?.query?.id || activeWorkspace?.id;

        const isCurrentOrder = CampaignOrder[columnId] === currentOrder;
        const isReversedOrder = currentOrder?.startsWith('-');

        if (isCurrentOrder) {
            newOrder = CampaignOrder[`${columnId}Reverse`];
        } else if (isReversedOrder) {
            newOrder = CampaignOrder[columnId];
        }

        router.replace(
            {
                pathname: `/workspaces/${activeWorkspaceId}`,
                query: {
                    order: newOrder,
                },
            },
            undefined,
            { shallow: true },
        );
    };

    const columns: CampaignTableColumnItem[] = [
        {
            name: CampaignColumnName.Name,
            text: t('name'),
            sortable: true,
        },
        {
            name: CampaignColumnName.IsFav,
            text: t('favorite'),
            sortable: true,
        },
        {
            name: CampaignColumnName.Status,
            text: t('status'),
            sortable: true,
        },
        {
            name: CampaignColumnName.Conversion,
            text: t('cvr'),
            sortable: true,
        },
        {
            name: CampaignColumnName.Contacts,
            text: t('contacts'),
            sortable: true,
        },
        {
            name: CampaignColumnName.CRM,
            text: t('contacts-status'),
            sortable: false,
        },
        {
            name: CampaignColumnName.Actions,
            text: '',
            sortable: false,
        },
    ].filter(Boolean) as CampaignTableColumnItem[];

    return (
        <>
            <div className="hidden pb-4 lg:block">
                <FunnelTable campaigns={campaigns} columns={columns} onSort={handleSort} />
            </div>

            {/* Mobile */}
            <div className="mb-4 flex flex-col gap-4 lg:hidden">
                {campaigns.map((campaign) => (
                    <TableItem campaign={campaign} key={campaign.id} />
                ))}
            </div>
        </>
    );
};
