import Image from 'next/image';
import Link from 'next/link';

import { getCampaignCoverImage, getCampaignIsArchived } from '@/app/campaigns/helpers';

import type { CampaignResource } from '@/app/campaigns/types';

export interface Props {
    campaign: CampaignResource;
    link: string;
}

const Picture = ({ src, name }: { src: string; name: string }) => (
    <div className="relative size-full overflow-hidden rounded-full border">
        <Image fill src={src} alt={`${name} cover image`} sizes="50vw" />
    </div>
);

const CoverImage = ({ campaign, link }: Props) => {
    const isArchived = getCampaignIsArchived(campaign);
    const coverImage = getCampaignCoverImage(campaign);
    const image = `${coverImage?.split('?')[0]}?ar=1:1&fit=crop&auto=compress&w=80`;

    return (
        <div className="size-10 flex-shrink-0">
            {isArchived ? (
                <Picture src={image} name={campaign?.attributes?.name} />
            ) : (
                <Link href={link}>
                    <Picture src={image} name={campaign?.attributes?.name} />
                </Link>
            )}
        </div>
    );
};

export default CoverImage;
