import { ENDPOINTS, MUTATION_KEYS, NAME } from '@/app/campaigns/constants';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { showToast } from '@/app/toasts/utils/showToast';
import { apiPost } from '@/core/api';
import { showNetworkError } from '@/core/api/helper';

import type { WorkspaceResource } from '@/app/workspaces/types';
import type { ResponseData } from '@/core/api/types';

export const useMoveCampaign = (campaignId: string) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: MUTATION_KEYS.moveCampaign(campaignId),
        mutationFn: async ({ workspaceId }: { workspaceId: string }) =>
            apiPost<ResponseData<WorkspaceResource>>(ENDPOINTS.POST.moveCampaigns(), {
                data: {
                    workspaceId,
                    campaignIds: [campaignId],
                },
            }),
        onError: (err) => {
            showNetworkError(err);
        },
        onSuccess: () => {
            showToast({
                message: `${NAME}:move-campaign-success-message`,
                type: 'success',
            });
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                predicate: (query) =>
                    // revalidate all campaigns queries
                    query.queryKey[0] === 'campaigns' ||
                    // revalidate the campaign query
                    (query.queryKey[0] === 'campaign' && query.queryKey[1] === campaignId),
            });
        },
    });
};
