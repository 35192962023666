import { NAME } from '@/app/campaigns/constants';

import intlFormatDistance from 'date-fns/intlFormatDistance';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useMemo, useRef } from 'react';

import RenameCampaignForm from '@/app/campaigns/components/RenameCampaignForm';
import { getCampaignIsArchived } from '@/app/campaigns/helpers';
import { getRenaming } from '@/app/campaigns/models/update';
import { useAppSelector } from '@/core/redux/hooks';
import { useHasOverflow } from '@/hooks/useHasOverflow';
import Tooltip from '@/ui/components/Tooltip';
import { cn } from '@/utils/cn';
import { getNow } from '@/utils/common';
import { hasIntl } from '@/utils/oldBrowsers';

import type { CampaignResource } from '@/app/campaigns/types';

export interface Props {
    campaign: CampaignResource;
    link: string;
}

const Title = ({ campaign, link }: Props) => {
    const { t } = useTranslation(NAME);
    const renamingCampaignId = useAppSelector(getRenaming);
    const { locale } = useRouter();

    const nameElementRef = useRef<HTMLParagraphElement | undefined>(undefined);
    const hasNameElementOverflow = useHasOverflow(nameElementRef, 'horizontal');

    const { name, updatedAt } = campaign.attributes;
    const isArchived = getCampaignIsArchived(campaign);
    const isActiveRenaming = renamingCampaignId === campaign.id;

    const nameContent = useMemo(
        () => (
            <Tooltip content={name} disabled={!hasNameElementOverflow}>
                <div
                    className="max-w-48 truncate text-sm font-medium text-gray-900 xl:max-w-52"
                    ref={nameElementRef}
                >
                    {name}
                </div>
            </Tooltip>
        ),
        [name, hasNameElementOverflow],
    );

    return (
        <div>
            <div className={cn('rounded-lg', { 'bg-gray-100': isActiveRenaming })}>
                {isActiveRenaming ? (
                    <RenameCampaignForm campaign={campaign} className="text-sm" />
                ) : isArchived ? (
                    nameContent
                ) : (
                    <Link href={link}>{nameContent}</Link>
                )}
            </div>

            {hasIntl && updatedAt && (
                <p className="mt-1 truncate text-xs text-gray-400">
                    {t('last updated', {
                        date: intlFormatDistance(new Date(updatedAt), getNow(), {
                            locale,
                            numeric: 'always',
                        }),
                    })}
                </p>
            )}
        </div>
    );
};

export default Title;
