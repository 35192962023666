import data from '@emoji-mart/data/sets/14/apple.json';
import { init } from 'emoji-mart';

let emojiMartInitialized = false;

export const initEmojiMartData = () => {
    if (!emojiMartInitialized) {
        emojiMartInitialized = true;

        init({ data });
    }
};
