import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { useCampaigns } from '@/app/campaigns/hooks/queries/useCampaigns';
import { getActiveWorkspaceId } from '@/app/workspaces/models/workspaces';
import { showNetworkError } from '@/core/api/helper';
import { useAppSelector } from '@/core/redux/hooks';
import { usePagination } from '@/hooks/usePagination';
import { CapabilitiesTarget, useUserCapabilities } from '@/hooks/useUserCapabilities';
import { cn } from '@/utils/cn';
import { tw } from '@/utils/tw';

interface Props {
    className?: string;
}

const chevronClasses = tw`relative inline-flex size-8 items-center justify-center rounded-md text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800`;

export const Pagination = ({ className }: Props) => {
    const { query, asPath } = useRouter();
    const currentPage = Number(query?.page as string) || 1;
    const wrapperClasses = cn('flex items-center justify-center bg-white px-4', className);
    const campaignCapability = useUserCapabilities(CapabilitiesTarget.Campaign);
    const activeWorkspaceId = useAppSelector(getActiveWorkspaceId) || (query?.id as string);

    const {
        data,
        isPending: fetchingCampaigns,
        error: fetchingCampaignsError,
    } = useCampaigns({
        filter: query.filter as string,
        search: query.search as string,
        workspaceId: (query?.workspaceId as string) || activeWorkspaceId,
        crmCampaignsOnly: !campaignCapability.canUpdate,
        page: String(currentPage),
    });
    const { meta } = data || {};

    const totalCount = meta?.totalCount ?? 0;
    const pageCount = meta?.pageCount ?? 0;
    const pageSize = Math.ceil(totalCount / pageCount);

    const paginationRange = usePagination({
        totalCount: totalCount,
        pageSize,
        currentPage,
    });

    if (fetchingCampaignsError) {
        showNetworkError(fetchingCampaignsError);

        return;
    }

    if (fetchingCampaigns || pageCount < 2 || !paginationRange) {
        return null;
    }

    let previousPage = currentPage - 1;
    let nextPage = currentPage + 1;

    if (previousPage < 1) {
        previousPage = pageCount;
    } else if (nextPage > pageCount) {
        nextPage = 1;
    }

    const getPaginationUrl = (page: number) => {
        const [pathname, queryString] = asPath.split('?');

        // adjusts the 'page' query param
        const searchParams = new URLSearchParams(queryString || '');
        searchParams.set('page', String(page));

        return `${pathname}?${searchParams.toString()}`;
    };

    return (
        <div className={wrapperClasses}>
            <div className="flex flex-1 items-center justify-center">
                <nav
                    className="relative z-0 flex w-full items-center justify-center space-x-2 rounded-md"
                    aria-label="Pagination"
                >
                    <Link role="button" href={getPaginationUrl(previousPage)}>
                        <span aria-current="page" className={chevronClasses}>
                            <ChevronLeftIcon className="size-5" />
                        </span>
                    </Link>

                    {paginationRange.map((page, index) => {
                        const linkClasses = cn(
                            'relative inline-flex size-8 items-center justify-center rounded-md text-sm font-medium',
                            {
                                'bg-blue-50 text-blue-500': page === currentPage,
                                'bg-white text-gray-500 hover:bg-gray-100 hover:text-gray-800':
                                    page !== currentPage,
                            },
                        );

                        if (page === '...') {
                            return (
                                <span
                                    className={cn('pointer-event-none', linkClasses)}
                                    key={`pagination-dots-${index}`}
                                >
                                    {page}
                                </span>
                            );
                        }

                        return (
                            <Link
                                role="button"
                                href={getPaginationUrl(page as number)}
                                key={`pagination-${page}`}
                                shallow
                            >
                                <span className={linkClasses}>{page}</span>
                            </Link>
                        );
                    })}

                    <Link role="button" href={getPaginationUrl(nextPage)}>
                        <div aria-current="page" className={chevronClasses}>
                            <ChevronRightIcon className="size-5" />
                        </div>
                    </Link>
                </nav>
            </div>
        </div>
    );
};
