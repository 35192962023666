import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';

import Popover from '@/ui/components/Popover';
import { stopPropagation } from '@/utils/common';

import WarningPopover from './WarningPopover';

interface Props {
    className?: string;
    iconClassName?: string;
    setHovered: (hovered: boolean) => void;
}

const V3WarningIcon = ({ className, iconClassName = 'w-4 h-4', setHovered }: Props) => {
    return (
        <Popover
            openOnHover
            onOpenChange={(open) => {
                setHovered(open);
            }}
            placement="right"
            mainAxisOffsetValue={5}
            crossAxisOffsetValue={0}
            fallbackPlacements={['top-start']}
            render={() => <WarningPopover />}
        >
            <button className={className} onClick={stopPropagation}>
                <ExclamationTriangleIcon className={iconClassName} />
            </button>
        </Popover>
    );
};

export default V3WarningIcon;
