import { NAME } from '@/app/campaigns/constants';

import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

import { CampaignFilter } from '@/app/campaigns/types';
import { useWorkspaces } from '@/app/workspaces/hooks/useWorkspaces';
import { cn } from '@/utils/cn';

export interface Props {
    show: boolean;
}

export const GlobalSearchToggle = ({ show }: Props) => {
    const { t } = useTranslation(NAME);
    const router = useRouter();
    const { sortedWorkspaces, activeWorkspace } = useWorkspaces();
    const isGlobalSearch = router?.query?.workspaceId?.includes(',');
    const allWorkspacesId = sortedWorkspaces.map((workspace) => workspace?.id).join(',');

    const handleToggle = (value: boolean) => async () => {
        const activeWorkspaceId = router?.query?.id || activeWorkspace?.id;

        router.replace(
            {
                query: {
                    id: router?.query?.id || activeWorkspace?.id,
                    filter: CampaignFilter.search,
                    search: router?.query?.search,
                    workspaceId: value === true ? allWorkspacesId : activeWorkspaceId,
                },
            },
            undefined,
            { shallow: true },
        );
    };

    const getButtonClassName = (active: boolean) =>
        cn('rounded-lg px-4 py-2 text-sm transition-all', {
            'bg-gray-100 text-gray-800': active,
            'text-gray-500 hover:bg-gray-100 hover:text-gray-800': !active,
        });

    return (
        <div
            className={cn(
                'absolute bottom-0 left-0 flex translate-y-9 gap-2 opacity-0 transition-all duration-200 ease-out',
                {
                    'translate-y-11 opacity-100': show,
                },
            )}
        >
            <button className={getButtonClassName(!isGlobalSearch)} onClick={handleToggle(false)}>
                {t('search-this-workspace')}
            </button>

            <button className={getButtonClassName(isGlobalSearch)} onClick={handleToggle(true)}>
                {t('search-all-workspaces')}
            </button>
        </div>
    );
};
