import { NAME } from '@/app/campaigns/constants';

import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { cn } from '@/utils/cn';

import type { ComponentProps, ReactNode } from 'react';

export interface Props {
    template: {
        title: string;
        description: string;
        background: string;
        link: string;
        icon: (props: ComponentProps<'svg'>) => ReactNode;
    };
}

const Template = ({ template }: Props) => {
    const { t } = useTranslation(NAME);

    return (
        <li className="flow-root">
            <div className="relative -m-2 flex items-center space-x-4 rounded-xl p-2 focus-within:ring-2 focus-within:ring-blue-500 hover:bg-gray-50">
                <div
                    className={cn(
                        template.background,
                        'flex size-16 flex-shrink-0 items-center justify-center rounded-lg',
                    )}
                >
                    <template.icon className="size-6 text-white" aria-hidden="true" />
                </div>
                <div>
                    <h3 className="text-sm font-semibold text-gray-900">
                        <Link href={template.link}>
                            <div className="focus:outline-none">
                                <span className="absolute inset-0" aria-hidden="true" />
                                {t(template.title)}
                                <span aria-hidden="true"> &rarr;</span>
                            </div>
                        </Link>
                    </h3>
                    <p className="mt-1 w-2/3 text-sm text-gray-500">{t(template.description)}</p>
                </div>
            </div>
        </li>
    );
};

export default Template;
