import { NAME } from '@/app/campaigns/constants';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useTranslation } from 'next-i18next';

import { cn } from '@/utils/cn';

import type { ReactNode, SVGProps } from 'react';

export interface Props {
    active: boolean;
    label: string;
    value: string;
    icon: (props: SVGProps<SVGSVGElement>) => ReactNode;
    onClick: (value: string) => void;
}

export const Option = ({ active, label, value, onClick, icon: Icon }: Props) => {
    const { t } = useTranslation(NAME);

    const handleClick = () => onClick(value);

    return (
        <DropdownMenu.Item asChild>
            <button
                className={cn(
                    'flex w-full cursor-pointer select-none items-center rounded-md p-2 text-sm outline-none',
                    active
                        ? 'bg-blue-50 text-blue-500'
                        : 'text-gray-500 hover:bg-gray-100 hover:text-gray-800',
                )}
                onClick={handleClick}
                role="option"
                aria-selected={active}
                aria-label={t(label)}
            >
                <Icon className="mr-3 size-4" />
                {t(label)}
            </button>
        </DropdownMenu.Item>
    );
};
