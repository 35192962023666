import { useEffect, useMemo, useRef } from 'react';
import slugify from 'slugify';

import type {
    HTMLInputTypeAttribute,
    KeyboardEventHandler,
    MutableRefObject,
    ReactEventHandler,
} from 'react';
import type { FieldRenderProps } from 'react-final-form';

export interface Props extends FieldRenderProps<string> {
    type: HTMLInputTypeAttribute;
    placeholder?: string;
    autoFocus?: boolean;
    slugged?: boolean;
    className?: string;
    customOnBlur?: () => void;
    selectOnFocus?: boolean;
    blurOnEscape?: boolean;
    onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
    size?: number;
    inputRef?: MutableRefObject<HTMLInputElement>;
}

const PlainTextInput = ({
    inputRef,
    input,
    type = 'text',
    placeholder,
    autoFocus,
    slugged,
    className,
    customOnBlur,
    selectOnFocus,
    blurOnEscape,
    onKeyDown,
    size = 20,
}: Props) => {
    const localInputRef = useRef<HTMLInputElement | null>(null);

    // modify value if necessary
    const handleChange = (event) => {
        const { onChange } = input;

        // slugify & trim
        const modifiedValue = slugged
            ? slugify(event.target.value, {
                  lower: true,
                  trim: false,
              })
            : event.target.value;

        onChange(modifiedValue);
    };

    const handleBlur = () => {
        if (customOnBlur) {
            setTimeout(customOnBlur);
        }
    };

    const handleFocus: ReactEventHandler<HTMLInputElement> | undefined = useMemo(() => {
        if (selectOnFocus) {
            return (event) => {
                event.currentTarget.select();
            };
        }
    }, [selectOnFocus]);

    useEffect(() => {
        if (blurOnEscape) {
            const escapeHandler = (event: KeyboardEvent) => {
                if (event.key === 'Escape') {
                    localInputRef.current?.blur();
                    inputRef?.current?.blur();
                }
            };

            window.addEventListener('keydown', escapeHandler, false);

            return () => {
                window.removeEventListener('keydown', escapeHandler, false);
            };
        }
    }, [blurOnEscape, inputRef]);

    return (
        <input
            ref={inputRef ?? localInputRef}
            onChange={handleChange}
            value={input.value}
            placeholder={placeholder}
            type={type}
            autoFocus={autoFocus}
            className={className}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onKeyDown={onKeyDown}
            size={size}
        />
    );
};

export default PlainTextInput;
