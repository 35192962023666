import Link from 'next/link';

import StatusBadge from '@/app/campaigns/components/Overview/Grid/CampaignCard/Footer/StatusBadge';
import { getCampaignIsArchived, getCampaignStatus, getEditorUrl } from '@/app/campaigns/helpers';

import type { CampaignResource, CampaignStatus } from '@/app/campaigns/types';

export interface Props {
    campaign: CampaignResource;
}

const Badge = ({ status, isArchived }: { status: CampaignStatus; isArchived: boolean }) => (
    <div className="text-sm text-gray-900">
        <StatusBadge status={status as CampaignStatus} archived={isArchived} />
    </div>
);

const Status = ({ campaign }: Props) => {
    const status = getCampaignStatus(campaign);
    const isArchived = getCampaignIsArchived(campaign);

    const editorUrl = getEditorUrl(campaign.id);

    if (isArchived) {
        return <Badge status={status as CampaignStatus} isArchived={isArchived} />;
    }

    return (
        <Link href={editorUrl}>
            <Badge status={status as CampaignStatus} isArchived={false} />
        </Link>
    );
};

export default Status;
