import { LS_LAST_ACTIVE_WORKSPACE_ID_KEY } from '@/app/workspaces/constants';

import Head from 'next/head';
import { useEffect } from 'react';

import Overview from '@/app/campaigns/components/Overview';
import TopBar from '@/app/campaigns/components/Overview/TopBar';
import V3SharingWarningBanner from '@/app/campaigns/components/Overview/V3SharingWarningBanner';
import { reset as resetWorkspaceStatus } from '@/app/crm/models/workspaceStatus';
import { getWorkspaceById, setActiveWorkspaceId } from '@/app/workspaces/models/workspaces';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { getIsWorkspaceEnv } from '@/utils/environments';
import { CapabilitiesTarget } from '@/utils/hooks/useUserCapabilities';
import { useUserCapabilities } from '@/utils/hooks/useUserCapabilities';

interface Props {
    id: string;
}

const Workspace = ({ id }: Props) => {
    const dispatch = useAppDispatch();

    const workspaceCapability = useUserCapabilities(CapabilitiesTarget.Workspaces);

    const workspace = useAppSelector((state) => getWorkspaceById(state, id));

    useEffect(() => {
        if (id) {
            dispatch(setActiveWorkspaceId(id));

            // We store the last active workspace ID and default flag
            // to correctly navigate on "Funnels" click with the new navigation bar.
            // When clicking "Funnels", the user shall be redirected back to the funnel
            // overview page of the last active workspace.
            localStorage.setItem(LS_LAST_ACTIVE_WORKSPACE_ID_KEY, id);
        }

        // store the id in localStorage so that we can easily navigate between academy and referrals and performance pages back to Funnels
    }, [dispatch, id, workspaceCapability.canUpdate, workspace]);

    // Makes sure workspace status are clean when loading the workspace
    useEffect(() => {
        dispatch(resetWorkspaceStatus());
    }, [dispatch, id]);

    const title = getIsWorkspaceEnv() ? 'Workspace Funnels' : 'Perspective Funnels';

    return (
        <>
            <Head>
                <title>{title}</title>
            </Head>

            <div className="mx-auto w-full max-w-6xl px-4 pb-16">
                <TopBar />
                <V3SharingWarningBanner workspaceId={id} />
                <Overview workspaceId={id} />
            </div>
        </>
    );
};

export default Workspace;
