import { useTranslation } from 'next-i18next';

import { cn } from '@/utils/cn';

import { CAMPAIGN_STATUS_KEYS, CAMPAIGN_STATUS_MAP, NAME } from '../../../../../constants';

import type { CampaignStatus } from '../../../../../types';

export interface Props {
    status: CampaignStatus;
    archived: boolean;
}

const StatusBadge = ({ status, archived }: Props) => {
    const { t } = useTranslation(NAME);
    const isSuccess = status === CAMPAIGN_STATUS_MAP.live.text && !archived;
    const isWarning = status === CAMPAIGN_STATUS_MAP.building.text && !archived;
    const isDanger =
        (status === CAMPAIGN_STATUS_MAP.offline.text ||
            status === CAMPAIGN_STATUS_MAP.error.text) &&
        !archived;
    const isNeutral =
        status === CAMPAIGN_STATUS_MAP.draft.text ||
        archived ||
        !CAMPAIGN_STATUS_KEYS.includes(status);

    const translation = archived ? 'archived' : status;

    const badgeClass = cn(
        'inline-block flex h-8 w-min flex-initial cursor-default items-center rounded-md px-3',
        {
            'bg-green-100': isSuccess,
            'bg-yellow-100': isWarning,
            'bg-red-100': isDanger,
            'bg-gray-100': isNeutral,
        },
    );

    const textClass = cn('text-xs capitalize', {
        'text-green-600': isSuccess,
        'text-yellow-600': isWarning,
        'text-red-600': isDanger,
        'text-gray-500': isNeutral,
    });

    return (
        <div className={badgeClass}>
            <p className={textClass}>{t(translation)}</p>
        </div>
    );
};

export default StatusBadge;
