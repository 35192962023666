import { NAME } from '@/app/campaigns/constants';

import { useTranslation } from 'next-i18next';

import { NoContacts } from '@/app/campaigns/components/Overview/Table/FunnelTable/Row/CRMStatusView/NoContacts';
import { StatusContactsBar } from '@/app/campaigns/components/Overview/Table/FunnelTable/Row/CRMStatusView/StatusContactsBar';
import WarningPopover from '@/app/campaigns/components/Overview/V3WarningIcon/WarningPopover';
import { getCampaignContactCount, getCampaignCRMStatus } from '@/app/campaigns/helpers';
import Popover from '@/ui/components/Popover';

import type { CampaignResource } from '@/app/campaigns/types';

export interface Props {
    campaign: CampaignResource;
    link: string;
}

export const CRMStatusView = ({ campaign, link }: Props) => {
    const { t } = useTranslation(NAME);
    const crmStatus = getCampaignCRMStatus(campaign);
    const totalContactsCount = getCampaignContactCount(campaign);
    const isFunnelOutdated = campaign?.attributes?.trackingVersion === 'v3';
    const isCRMEmpty = totalContactsCount === 0;

    if (isFunnelOutdated) {
        return (
            <Popover
                openOnHover
                placement="top"
                mainAxisOffsetValue={5}
                crossAxisOffsetValue={0}
                fallbackPlacements={['top-start']}
                render={() => <WarningPopover />}
            >
                <span className="cursor-default text-sm leading-7 text-gray-400">
                    {t('outdated-funnel')}
                </span>
            </Popover>
        );
    }

    if (isCRMEmpty) {
        return <NoContacts link={link} />;
    }

    const crmStatusesWithContacts = crmStatus.filter(({ count }) => count > 0);

    return (
        <StatusContactsBar
            link={link}
            statusWithContactsList={crmStatusesWithContacts}
            totalContacts={totalContactsCount}
        />
    );
};
