import { ChevronUpDownIcon, ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { useRouter } from 'next/router';

import { cn } from '@/utils/cn';

import type { CampaignOrder } from '@/app/campaigns/types';

interface Props {
    id: string;
    title: string;
    sortable: boolean;
    onSort: (id: string) => void;
}

const ColumnHead = ({ id, title, sortable, onSort }: Props) => {
    const router = useRouter();
    const order = router.query.order as CampaignOrder;

    const isOrdered = order?.includes(id);
    const isAscending = order?.charAt(0) === '-';

    const handleSort = () => {
        onSort(id);
    };

    const headClassName = cn(
        'cursor-default select-none py-3 pl-3 pr-1.5 text-left text-xs font-medium tracking-wider text-gray-400 first:rounded-tl-md last:rounded-tr-md xl:pl-5 xl:pr-3',
        {
            'cursor-pointer transition-colors hover:text-gray-600': sortable,
        },
    );

    return (
        <th scope="col" className={headClassName} onClick={sortable ? handleSort : undefined}>
            <div className="flex items-center">
                <p className="truncate">{title}</p>
                {sortable &&
                    (isOrdered ? (
                        isAscending ? (
                            <ChevronUpIcon className="ml-1 inline-block size-4" />
                        ) : (
                            <ChevronDownIcon className="ml-1 inline-block size-4" />
                        )
                    ) : (
                        <ChevronUpDownIcon className="ml-1 inline-block size-4" />
                    ))}
            </div>
        </th>
    );
};

export default ColumnHead;
