import Link from 'next/link';

import { getAnalyticsContactsUrl } from '@/app/campaigns/helpers';
import { getCrmUrl } from '@/app/crm/helpers';
import { CapabilitiesTarget, useUserCapabilities } from '@/hooks/useUserCapabilities';

export interface Props {
    campaignId: string;
    number: number;
    text: string;
    isV3Funnel: boolean;
}

const BadgeButton = ({ number, text }: { number: number; text: string }) => {
    return (
        <div className="mr-2 flex h-8 flex-initial items-center rounded-md bg-blue-50 px-3">
            <span className="text-xs text-blue-500">{`${number} ${text}`}</span>
        </div>
    );
};

const KpiBadge = ({ campaignId, number, text, isV3Funnel }: Props) => {
    const contactsUrl = isV3Funnel ? getAnalyticsContactsUrl(campaignId) : getCrmUrl(campaignId);
    const { canRead: canReadContacts } = useUserCapabilities(CapabilitiesTarget.Contacts);

    if (!canReadContacts) {
        return <BadgeButton number={number} text={text} />;
    }

    return (
        <Link href={contactsUrl} className="bump">
            <BadgeButton number={number} text={text} />
        </Link>
    );
};

export default KpiBadge;
