import { MUTATION_KEYS, QUERY_KEYS } from '@/app/campaigns/constants';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useUpdateCampaign } from '@/app/campaigns/hooks/mutations/useUpdateCampaign';

import type { CampaignResource } from '@/app/campaigns/types';

export const useRenameCampaign = (campaignId: string) => {
    const updateCampaign = useUpdateCampaign(campaignId);
    const queryClient = useQueryClient();

    // Snapshot the previous campaign
    const previousCampaign = queryClient.getQueryData<CampaignResource>(
        QUERY_KEYS.campaign(campaignId),
    );

    return useMutation({
        mutationKey: MUTATION_KEYS.renameCampaign(campaignId),
        mutationFn: (newCampaignName: string) =>
            updateCampaign.mutateAsync({
                ...previousCampaign,
                attributes: {
                    ...previousCampaign.attributes,
                    name: newCampaignName,
                },
            }),
    });
};
