import { NAME } from '@/app/campaigns/constants';
import { STATUS_COLOR_MAP } from '@/app/crm/constants';

import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { CapabilitiesTarget, useUserCapabilities } from '@/hooks/useUserCapabilities';
import Tooltip from '@/ui/components/Tooltip';
import { cn } from '@/utils/cn';

import type { CampaignCRMStatus } from '@/app/crm/types';

type Props = {
    statusWithContactsList: CampaignCRMStatus[];
    totalContacts: number;
    link: string;
};

export const StatusContactsBar = ({ statusWithContactsList, link, totalContacts }: Props) => {
    const { t } = useTranslation(NAME);
    const { canRead: canReadContacts } = useUserCapabilities(CapabilitiesTarget.Contacts);

    const statusBarList = statusWithContactsList.map((status: CampaignCRMStatus, index: number) => {
        const percentage = Math.round((status.count / totalContacts) * 100);
        const colorClass = STATUS_COLOR_MAP[status.color]?.backgroundSolid;

        return (
            <Tooltip
                key={`${status.name}-${status.color}`}
                content={`${status.name}: ${status.count} ${t('contacts')}`}
                offsetValue={0}
            >
                <div className="h-6 rounded-[1px] py-2.5" style={{ width: `${percentage}%` }}>
                    <div
                        className={cn('h-1.5 w-full', colorClass, {
                            'rounded-l-full': index === 0,
                            'rounded-r-full': index === statusWithContactsList.length - 1,
                        })}
                    />
                </div>
            </Tooltip>
        );
    });

    if (!canReadContacts) {
        return (
            <div className="align-center flex h-6 w-48 gap-0.5 overflow-hidden xl:w-56">
                {...statusBarList}
            </div>
        );
    }

    return (
        <Link href={link} className="flex items-center gap-2">
            <div className="align-center flex h-6 w-48 gap-0.5 overflow-hidden xl:w-56">
                {...statusBarList}
            </div>
        </Link>
    );
};
