import { MUTATION_KEYS, NAME, ENDPOINTS } from '@/app/campaigns/constants';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { showToast } from '@/app/toasts/utils/showToast';
import { apiDelete } from '@/core/api';
import { showNetworkError } from '@/core/api/helper';

export const useDeleteCampaign = (campaignId: string) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: MUTATION_KEYS.deleteCampaign(campaignId),
        mutationFn: async () => apiDelete(ENDPOINTS.DELETE.deleteCampaign(campaignId)),
        onSuccess: () => {
            showToast({ type: 'success', message: `${NAME}:delete-success` });
        },
        onError: (err) => {
            showNetworkError(err);
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['campaigns'],
            });
        },
    });
};
