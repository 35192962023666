import ColumnHead from './ColumnHead';
import { Row } from './Row';

import type { CampaignResource, CampaignTableColumnItem } from '@/app/campaigns/types';

interface Props {
    columns: CampaignTableColumnItem[];
    campaigns: CampaignResource[];
    onSort: (id: string) => void;
}

export const FunnelTable = ({ columns, campaigns, onSort }: Props) => {
    return (
        <div className="flex flex-col overflow-x-auto overflow-y-hidden rounded-2xl border shadow-smooth">
            <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                    <tr>
                        {columns.map((column) => (
                            <ColumnHead
                                key={column.name}
                                id={column.name}
                                title={column.text}
                                sortable={column.sortable}
                                onSort={onSort}
                            />
                        ))}
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                    {campaigns.map((campaign) => {
                        return <Row key={campaign.id} campaign={campaign} columns={columns} />;
                    })}
                </tbody>
            </table>
        </div>
    );
};
