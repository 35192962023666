import { UserIcon } from '@heroicons/react/24/outline';

interface Props {
    count: number;
}

export const TotalContacts = ({ count }: Props) => (
    <div className="inline-flex h-8 items-center gap-1 rounded-md bg-blue-50 p-2 text-xs text-blue-500">
        <UserIcon className="size-4" />
        {count}
    </div>
);
