import { NAME } from '@/app/campaigns/constants';

import { useTranslation } from 'next-i18next';
import { Field, Form } from 'react-final-form';

import { getSearching } from '@/app/campaigns/models/campaigns';
import { useAppSelector } from '@/core/redux/hooks';
import TextInput from '@/ui/components/_ReduxFieldComponents/TextInput';

export interface Props {
    onChange: (values: { name: string }) => void;
}

const MobileSearch = ({ onChange }: Props) => {
    const { t } = useTranslation(NAME);
    const searching = useAppSelector(getSearching);

    return (
        <Form onSubmit={onChange}>
            {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} className="w-full">
                    <Field
                        name="name"
                        component={TextInput}
                        onChange={handleSubmit}
                        type="search"
                        loading={searching}
                        placeholder={t('common:search')}
                    />
                </form>
            )}
        </Form>
    );
};

export default MobileSearch;
