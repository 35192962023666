import {
    CAMPAIGN_STATUS,
    ENDPOINTS,
    FILTER_QUERY_MAP,
    QUERY_KEYS,
} from '@/app/campaigns/constants';

import { useQuery } from '@tanstack/react-query';

import { CampaignOrder } from '@/app/campaigns/types';
import { apiGet } from '@/core/api';
import { getDataFromResponse, getMetaFromResponse } from '@/core/api/helper';

import type { CampaignResource } from '@/app/campaigns/types';
import type { ResponseData } from '@/core/api/types';

export const useCampaigns = ({
    filter,
    search,
    workspaceId,
    crmCampaignsOnly,
    order = CampaignOrder.isFavReverse,
    page = '1',
}: {
    filter?: string;
    search?: string;
    page?: string;
    order?: CampaignOrder;
    crmCampaignsOnly?: boolean;
    workspaceId: string;
}) => {
    return useQuery({
        queryKey: QUERY_KEYS.campaigns({
            filter,
            search,
            workspaceId,
            page,
            crmCampaignsOnly,
            order,
        }),
        queryFn: async () => {
            try {
                const params = { ...FILTER_QUERY_MAP[filter], page, search, workspaceId, order };

                if (crmCampaignsOnly) {
                    params.status = CAMPAIGN_STATUS.live;
                    params.trackingVersion = 'v4';
                }

                const response = await apiGet<ResponseData<CampaignResource[]>>(
                    ENDPOINTS.GET.filter(),
                    {
                        params,
                    },
                );

                return {
                    campaigns: getDataFromResponse(response),
                    meta: getMetaFromResponse(response),
                };
            } catch (error) {
                return Promise.reject(error);
            }
        },
    });
};
