import { useTranslation } from 'next-i18next';
import slugify from 'slugify';

import { cn } from '@/utils/cn';
import { turnOffAutoComplete } from '@/utils/inputFields';

import LoadingSpinner from '../../LoadingSpinner';

import type { ChangeEvent } from 'react';
import type { FieldRenderProps } from 'react-final-form';

export interface Props extends FieldRenderProps<string> {
    label?: string;
    placeholder?: string;
    autoFocus?: boolean;
    slugged?: boolean;
    readonly?: boolean;
    className?: string;
    onChange?: (value: string) => void;
    allowAutoComplete?: boolean;
}

const TextInput = ({
    label,
    input,
    meta,
    placeholder,
    autoFocus,
    slugged,
    loading,
    readonly = false,
    allowAutoComplete = false,
    className = '',
    onChange: onChangeFromProps,
}: Props) => {
    const { t, i18n } = useTranslation('common');
    const { onChange } = input;

    const finalPlaceholder =
        !!placeholder && i18n.exists(placeholder) ? t(placeholder) : placeholder;

    // modify value if necessary
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (readonly) {
            return;
        }

        // slugify & trim
        const modifiedValue = slugged
            ? slugify(event.target.value, {
                  lower: true,
                  trim: false,
              })
            : event.target.value;

        onChange(modifiedValue);
        onChangeFromProps?.(modifiedValue);
    };

    const inputClasses = cn(
        'form-input block h-10 w-full rounded-md border-gray-300 shadow-sm outline-none focus:border-blue-500 focus:ring-blue-500 sm:text-sm',
        className,
        {
            'border-red-500': meta.invalid && meta.touched,
            'border-gray-300': !meta.invalid && meta.touched,
            'bg-gray-100': readonly,
        },
    );

    return (
        <div>
            {label && (
                <label
                    className="mb-2 block text-sm font-semibold text-gray-900"
                    htmlFor={input.name}
                >
                    {label}
                </label>
            )}

            <input
                id={input.name}
                onChange={handleChange}
                value={input.value}
                className={inputClasses}
                placeholder={finalPlaceholder}
                type={input.type}
                autoFocus={autoFocus}
                readOnly={readonly}
                {...(!allowAutoComplete ? turnOffAutoComplete() : {})}
            />

            {loading && (
                <div
                    className={cn('absolute inset-y-0 my-auto flex items-center transition-all', {
                        'right-10': !!input.value,
                        'right-4': !input.value,
                    })}
                >
                    <LoadingSpinner size="tiny" />
                </div>
            )}

            {meta.touched && meta.error && (
                <span className="text-xs text-red-500">{t(meta.error)}</span>
            )}
        </div>
    );
};

export default TextInput;
