import { CAMPAIGN_FILTER_OPTIONS, NAME } from '@/app/campaigns/constants';

import { ChevronDownIcon } from '@heroicons/react/20/solid';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Option } from '@/app/campaigns/components/Overview/TopBar/Filter/Option';
import { CampaignFilter } from '@/app/campaigns/types';
import { useWorkspaces } from '@/app/workspaces/hooks/useWorkspaces';
import { cn } from '@/utils/cn';

export interface Props {
    filterAllLabel?: string;
    options?: CampaignFilter[];
}

export const Filter = ({ filterAllLabel, options }: Props) => {
    const { t } = useTranslation(NAME);

    const router = useRouter();
    const { activeWorkspace } = useWorkspaces();

    const filter = router?.query?.filter || CampaignFilter.all;
    const [open, setOpen] = useState(false);

    const isFilterAllSelected = filter === CampaignFilter.all;
    const showFilterAllLabel = filterAllLabel && isFilterAllSelected;

    const filterOptions = useMemo(
        () =>
            options?.length
                ? CAMPAIGN_FILTER_OPTIONS.filter((option) => options.includes(option.value))
                : CAMPAIGN_FILTER_OPTIONS,
        [options],
    );

    const handleSelect = async (option: CampaignFilter) => {
        const activeWorkspaceId = router?.query?.id || activeWorkspace?.id;

        await router.replace(
            {
                pathname: `/workspaces/${activeWorkspaceId}`,
                query: {
                    filter: option,
                },
            },
            undefined,
            { shallow: true },
        );
    };

    return (
        <DropdownMenu.Root onOpenChange={setOpen}>
            <DropdownMenu.Trigger asChild>
                <button
                    className={cn(
                        'group relative z-20 flex cursor-pointer items-center outline-none',
                        'text-xl font-semibold sm:text-3xl',
                    )}
                >
                    <span
                        className="text-xl font-semibold text-gray-800 sm:text-3xl"
                        id="active-campaign-filter"
                    >
                        {showFilterAllLabel ? filterAllLabel : t(`filter-${filter}`)}
                    </span>
                    <div
                        className={cn(
                            'ml-1 rotate-0 text-gray-500 transition-transform duration-200 ease-out group-hover:text-gray-800 sm:mt-1',
                            { 'rotate-180 text-gray-800': open },
                        )}
                    >
                        <ChevronDownIcon className="size-5 sm:size-8" />
                    </div>
                </button>
            </DropdownMenu.Trigger>

            <DropdownMenu.Portal>
                <DropdownMenu.Content
                    role="listbox"
                    aria-labelledby="active-campaign-filter"
                    className="z-20 origin-top data-[state=closed]:animate-dropdown-out data-[state=open]:animate-dropdown-in"
                    sideOffset={6}
                    align="start"
                    side="bottom"
                    asChild
                >
                    <div className="flex w-56 flex-col gap-1 rounded-xl border bg-white p-2 shadow-dropdown">
                        {filterOptions.map((option) => {
                            return (
                                <Option
                                    key={`${option.label}-${option.value}`}
                                    onClick={handleSelect}
                                    label={option.label}
                                    value={option.value}
                                    icon={option.icon}
                                    active={filter === option.value}
                                />
                            );
                        })}
                    </div>
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    );
};
