import { useRenameCampaign } from '@/app/campaigns/hooks/mutations/useRenameCampaign';
import { setRenaming } from '@/app/campaigns/models/update';
import { useAppDispatch } from '@/core/redux/hooks';

import Form from './Form';

import type { CampaignResource } from '@/app/campaigns/types';

export interface Props {
    campaign: CampaignResource;
    className?: string;
}

const RenameCampaignForm = ({ campaign, className }: Props) => {
    const dispatch = useAppDispatch();
    const { mutate: renameCampaign } = useRenameCampaign(campaign.id);

    const handleSubmit = (values: CampaignResource) => {
        const newCampaignName = values?.attributes?.name;

        if (!newCampaignName) {
            return;
        }

        renameCampaign(newCampaignName);
        dispatch(setRenaming(''));
    };

    return <Form initialValues={campaign} className={className} onSubmit={handleSubmit} />;
};

export default RenameCampaignForm;
