import Link from 'next/link';
import { memo, useCallback, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';

import { ImageContent } from '@/app/campaigns/components/Overview/Grid/CampaignCard/ImageContent';
import {
    getCampaignIsArchived,
    getCampaignTrackingVersion,
    getLinkUrl,
    getMinifiedCoverImage,
} from '@/app/campaigns/helpers';
import { useRestoreCampaign } from '@/app/campaigns/hooks/mutations/useRestoreCampaign';
import { usePreloadEditor } from '@/app/campaigns/hooks/usePreloadEditor';
import { getCrmUrl } from '@/app/crm/helpers';
import { useMetricsPageEnabled } from '@/core/loli-feature-flags/features/metricsPage';
import { cn } from '@/utils/cn';
import { CapabilitiesTarget, useUserCapabilities } from '@/utils/hooks/useUserCapabilities';
import { tw } from '@/utils/tw';

import { Body } from './Body';
import Footer from './Footer';
import { FavButton } from '../../FavButton';
import V3WarningIcon from '../../V3WarningIcon';

import type { CampaignResource } from '../../../../types';

export interface Props {
    campaign: CampaignResource;
}

const iconClassName = tw`bump absolute flex size-8 items-center justify-center rounded-md bg-gray-800/50 text-white transition-all`;

const CampaignCard = ({ campaign }: Props) => {
    const [isHovered, setIsHovered] = useState(false);
    const preload = usePreloadEditor(campaign.id);
    const isArchived = getCampaignIsArchived(campaign);
    const trackingVersion = getCampaignTrackingVersion(campaign);
    const isV3Funnel = trackingVersion === 'v3';
    const isMetricsPageAvailable = useMetricsPageEnabled();

    const { mutate: restoreCampaign, isPending: isRestoring } = useRestoreCampaign(campaign.id);

    const { canUpdate } = useUserCapabilities(CapabilitiesTarget.Campaign);
    const link = canUpdate
        ? getLinkUrl({
              isMobile,
              isV3Funnel,
              campaignId: campaign.id,
              hasMetricsPage: !!isMetricsPageAvailable,
          })
        : getCrmUrl(campaign.id);

    const handleRestore = useCallback(() => {
        restoreCampaign();
    }, [restoreCampaign]);

    const coverImageSrc = useMemo(() => getMinifiedCoverImage(campaign), [campaign]);

    return (
        <div
            className={cn(
                'group relative rounded-2xl border transition-all duration-500 ease-out',
                {
                    '-translate-y-1 shadow-card-hover': isHovered,
                    'hover:-translate-y-1 hover:shadow-card-hover': !isHovered,
                },
            )}
            onMouseEnter={isArchived || !canUpdate ? undefined : preload}
        >
            {isArchived ? (
                <ImageContent
                    isArchived={isArchived}
                    src={coverImageSrc}
                    onRestore={handleRestore}
                    alt={campaign.attributes.name}
                />
            ) : (
                <div>
                    <Link href={link}>
                        <ImageContent
                            isArchived={isArchived}
                            src={coverImageSrc}
                            onRestore={handleRestore}
                            alt={campaign.attributes.name}
                            isRestoring={isRestoring}
                        />
                    </Link>
                    {isV3Funnel && (
                        <V3WarningIcon
                            className={cn(
                                'left-4 top-4 text-amber-500 opacity-100 outline-none backdrop-blur',
                                iconClassName,
                            )}
                            setHovered={setIsHovered}
                        />
                    )}
                    <FavButton
                        campaignId={campaign.id}
                        disabled={!canUpdate || isArchived}
                        className={cn('right-4 top-4 backdrop-blur transition-all', iconClassName, {
                            'origin-top-right scale-75 opacity-0 group-hover:scale-100 group-hover:opacity-100':
                                !campaign?.attributes?.isFav && !isHovered,
                            'opacity-100': campaign?.attributes?.isFav || isHovered,
                        })}
                    />
                </div>
            )}
            <Body campaign={campaign} link={link} />
            <div className="p-4 pt-0">
                <Footer campaign={campaign} onMoreMenuOpenChange={setIsHovered} />
            </div>
        </div>
    );
};

export default memo(CampaignCard);
