import { useEffect } from 'react';

import { getDisplayMode, setDisplayMode } from '@/app/campaigns/models/overview';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';

import { CAMPAIGN_LIST_DISPLAY_MODE_KEY } from '../constants';

import type { OverviewDisplayMode } from '../types';

export const useLastDisplayedMode = () => {
    const dispatch = useAppDispatch();
    const displayMode = useAppSelector(getDisplayMode);

    useEffect(() => {
        const lastDisplayMode = localStorage.getItem(CAMPAIGN_LIST_DISPLAY_MODE_KEY);

        if (lastDisplayMode) {
            dispatch(setDisplayMode(lastDisplayMode as OverviewDisplayMode));
        }
    }, [dispatch]);

    return displayMode;
};
