import {
    CalendarIcon,
    SwatchIcon,
    CursorArrowRaysIcon,
    GiftIcon,
    IdentificationIcon,
    VideoCameraIcon,
} from '@heroicons/react/24/outline';

export const templates = [
    {
        id: 'appointment',
        title: 'template-appointment',
        description: 'template-appointment-description',
        background: 'bg-blue-500',
        icon: CalendarIcon,
        link: '/new/appointment',
    },
    {
        id: 'raffle',
        title: 'template-raffle',
        description: 'template-raffle-description',
        background: 'bg-teal-500',
        icon: CursorArrowRaysIcon,
        link: '/new/contest',
    },
    {
        id: 'product-finder',
        title: 'template-product-finder',
        description: 'template-product-finder-description',
        background: 'bg-rose-500',
        icon: SwatchIcon,
        link: '/new/product-finder',
    },
    {
        id: 'gift',
        title: 'template-gift',
        description: 'template-gift-description',
        background: 'bg-pink-500',
        icon: GiftIcon,
        link: '/new/coupon',
    },
    {
        id: 'recruiting',
        title: 'template-recruiting',
        description: 'template-recruiting-description',
        background: 'bg-yellow-500',
        icon: IdentificationIcon,
        link: '/new/experience-recruiting',
    },
    {
        id: 'webinar',
        title: 'template-webinar',
        description: 'template-webinar-description',
        background: 'bg-gray-500',
        icon: VideoCameraIcon,
        link: '/new/live-webinar',
    },
];
