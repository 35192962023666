import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { cn } from '@/utils/cn';

import MenuItem from './Item';

import type { MenuItems } from './MoreMenu.controller';
import type { PopperContentProps } from '@radix-ui/react-popper';

export interface Props {
    menuItems: MenuItems;
    campaignId: string;
    align?: PopperContentProps['align'];
    hasPortal?: boolean;
    onOpenChange?: (open: boolean) => void;
}

const MoreMenuView = ({ menuItems, campaignId, align, onOpenChange, hasPortal = true }: Props) => {
    const handleOpenChange = (isOpen: boolean) => {
        if (typeof onOpenChange === 'function') {
            onOpenChange(isOpen);
        }
    };

    const content = (
        <DropdownMenu.Content
            className={cn(
                'origin-bottom-right data-[state=closed]:animate-dropdown-out data-[state=open]:animate-dropdown-in',
                {
                    'origin-top-right': align === 'start',
                },
            )}
            side="left"
            align={align ?? 'end'}
            sideOffset={6}
            asChild
        >
            <div className="z-10 w-52 divide-y rounded-lg border bg-white shadow-dropdown">
                {menuItems.map((group, index) => {
                    return (
                        <DropdownMenu.Group
                            key={`${campaignId}-moreMenuGroup-${index}`}
                            className="flex flex-col gap-0.5 p-2 outline-none"
                        >
                            {group.map((item) => (
                                <MenuItem key={item.text} hasPortal={hasPortal} {...item} />
                            ))}
                        </DropdownMenu.Group>
                    );
                })}
            </div>
        </DropdownMenu.Content>
    );

    return (
        <DropdownMenu.Root onOpenChange={handleOpenChange}>
            <DropdownMenu.Trigger asChild>
                <button
                    className={cn(
                        'bump flex size-8 items-center justify-center rounded-lg outline-none',
                        'data-[state=open]:bg-gray-100 data-[state=open]:text-gray-800',
                        'data-[state=closed]:text-gray-500 data-[state=closed]:hover:bg-gray-100 data-[state=closed]:hover:text-gray-800',
                    )}
                >
                    <EllipsisHorizontalIcon className="size-5" />
                </button>
            </DropdownMenu.Trigger>

            {hasPortal ? <DropdownMenu.Portal>{content}</DropdownMenu.Portal> : content}
        </DropdownMenu.Root>
    );
};

export default MoreMenuView;
