import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

import type { RefObject } from 'react';

export const useHasOverflow = (
    ref: RefObject<HTMLElement>,
    type: 'vertical' | 'horizontal',
    callback?: (hasOverflow: boolean) => void,
) => {
    const [hasOverflow, setHasOverflow] = useState(false);

    const checkIfOverflowing = useCallback(() => {
        const element = ref.current;

        if (!element) {
            setHasOverflow(false);
        } else if (type === 'vertical') {
            setHasOverflow(element.scrollHeight > element.clientHeight);
        } else if (type === 'horizontal') {
            setHasOverflow(element.scrollWidth > element.clientWidth);
        }
    }, [type, ref]);

    useEffect(() => {
        callback?.(hasOverflow);
    }, [callback, hasOverflow]);

    useLayoutEffect(() => {
        checkIfOverflowing();
    }, [checkIfOverflowing]);

    useLayoutEffect(() => {
        const element = ref.current;

        if (element) {
            const mutationObserver = new MutationObserver(checkIfOverflowing);
            mutationObserver.observe(element, {
                childList: true,
                subtree: true,
                characterData: true,
                attributes: true,
            });

            const resizeObserver = new ResizeObserver(checkIfOverflowing);
            resizeObserver.observe(element);

            return () => {
                mutationObserver.disconnect();
                resizeObserver.disconnect();
            };
        }
    }, [ref, checkIfOverflowing]);

    return hasOverflow;
};
