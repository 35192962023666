import { ENDPOINTS, MUTATION_KEYS, NAME } from '@/app/campaigns/constants';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getCampaignName } from '@/app/campaigns/helpers';
import { showToast } from '@/app/toasts/utils/showToast';
import { apiPost } from '@/core/api';
import { getDataFromResponse, showNetworkError } from '@/core/api/helper';

import type { CampaignResource } from '@/app/campaigns/types';
import type { ResponseData } from '@/core/api/types';

export const useRestoreCampaign = (campaignId: string) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: MUTATION_KEYS.restoreCampaign(campaignId),
        mutationFn: async () =>
            apiPost<ResponseData<CampaignResource>>(ENDPOINTS.POST.restoreCampaign(campaignId), {}),
        onSuccess: (response) => {
            const restoredCampaign = getDataFromResponse(response);

            showToast({
                type: 'success',
                message: `${NAME}:restore-success-message`,
                description: getCampaignName(restoredCampaign),
            });
        },
        onError: (err) => {
            showNetworkError(err);
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['campaigns'],
            });
        },
    });
};
