import CampaignCard from './CampaignCard';

import type { CampaignResource } from '../../../types';

export interface Props {
    campaigns: CampaignResource[];
}

export const Grid = ({ campaigns }: Props) => {
    return (
        <div className="grid grid-cols-1 gap-12 pb-12 sm:grid-cols-2 lg:grid-cols-3">
            {campaigns.map((campaign) => {
                return <CampaignCard key={`${campaign.id}-card`} campaign={campaign} />;
            })}
        </div>
    );
};
