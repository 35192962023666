import {
    NAME,
    DUPLICATION_LEARN_MORE_ARTICLE_URL_DE,
    DUPLICATION_LEARN_MORE_ARTICLE_URL_EN,
} from '@/app/campaigns/constants';

import Link from 'next/link';
import { useRouter } from 'next/router';
import { Trans } from 'next-i18next';

const WarningPopover = () => {
    const { locale } = useRouter();
    const isDeLocale = locale === 'de';

    const learnMoreLink = isDeLocale
        ? DUPLICATION_LEARN_MORE_ARTICLE_URL_DE
        : DUPLICATION_LEARN_MORE_ARTICLE_URL_EN;

    return (
        <div className="flex max-w-sm items-center rounded-md bg-gray-800 px-2 py-1.5 font-sans text-sm text-white">
            <span className="whitespace-pre-line">
                <Trans
                    i18nKey={`${NAME}:v3-outdated-funnel-warning`}
                    components={{
                        url: (
                            <Link
                                target="_blank"
                                href={learnMoreLink}
                                className="cursor-pointer font-semibold text-white underline"
                            />
                        ),
                    }}
                />
            </span>
        </div>
    );
};

export default WarningPopover;
